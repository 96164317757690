import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import "./styles.css";

const YsDeleteButton = ({ onClick = () => {} }) => {
  return (
    <Tooltip title={"Edit Question"}>
      <div className={"edit-button"} onClick={onClick}>
        <EditOutlined />
      </div>
    </Tooltip>
  );
};

export default YsDeleteButton;
