const ENVIRONMENT = {
  DEVELOPMENT: "development",
  PRODUCTION: "production"
};
const APP = {
  development: {API_PATH: `http://localhost:5000`},
  production: {API_PATH: `https://better-together-api.herokuapp.com/`}
};
export const activeEnvironment =
  process.env.NODE_ENV === "development" ? ENVIRONMENT.DEVELOPMENT : ENVIRONMENT.PRODUCTION; // CHANGE HERE

const APP_CONFIG = APP[activeEnvironment];

export default APP_CONFIG;
