import React, {useState} from "react";
import moment from "moment";
import {Form, Row, Col, Card, Layout, Input, Button, Radio} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import "antd/dist/antd.css";
import "./App.css";
import io from "socket.io-client";
import YsEditButton from "./Components/YsEditButton";
import YsDeleteButton from "./Components/YsDeleteButton";
import YsEditModal from "./Components/YsEditModal";
import {getUserId} from "./helpers";
import APP_CONFIG from "./env-config";

const userId = getUserId();

const socket = io.connect(APP_CONFIG.API_PATH);
const {Header, Footer, Content} = Layout;

const App = () => {
  const [searchValue, setSearchValue] = useState("");
  const [userCount, setUserCount] = useState(0);
  const [gridColCount, setGridColCount] = useState(6);
  const [dataSource, setDataSource] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [form] = Form.useForm();

  socket.on("userCount", newUserCount => {
    setUserCount(newUserCount);
  });
  socket.on("userCount", newUserCount => {
    setUserCount(newUserCount);
  });
  socket.on("refreshData", newData => {
    setDataSource(newData);
  });
  socket.on("createQuestion", newQuestion => {
    setDataSource([newQuestion, ...dataSource]);
  });
  socket.on("deleteQuestion", questionId => {
    setDataSource(dataSource.filter(el => el._id !== questionId));
  });
  socket.on("editQuestion", edittedQuestion => {
    setDataSource([edittedQuestion, ...dataSource.filter(el => el?._id !== edittedQuestion?._id)]);
  });

  const onFinish = values => {
    const {question, answer} = values;
    if (question && answer) {
      socket.emit("createQuestion", {
        userId,
        question,
        answer
      });
      form.resetFields();
    }
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  const deleteQuestion = questionId => {
    if (questionId) socket.emit("deleteQuestion", questionId);
  };
  const editQuestion = question => {
    if (question) {
      socket.emit("editQuestion", question);
      setVisibleModal(false);
    }
  };

  const prepareText = text =>
    String(text)
      .toLowerCase()
      .replace(/(\s|\n)/g, "");

  const filterQuestions = () =>
    searchValue?.length > 0
      ? dataSource.filter(data => {
          const preparedDataQuestion = prepareText(data?.question);
          const preparedSearchValue = prepareText(searchValue);
          return (
            preparedDataQuestion.includes(preparedSearchValue) || preparedSearchValue.includes(preparedDataQuestion)
          );
        })
      : dataSource;

  const radioOptions = [
    {label: "2", value: 12},
    {label: "3", value: 8},
    {label: "4", value: 6}
  ];

  return (
    <Layout style={{minHeight: "100vh"}}>
      <Header
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center"
        }}>
        <span className="online-circle"></span>
        <b style={{color: "#f2f2f2", marginLeft: 10}}>{userCount}</b>
      </Header>
      <Layout>
        <Content style={{overflow: "scroll", height: "100vh"}}>
          <Col span={20} offset={2} style={{marginBottom: 265}}>
            <Card
              style={{
                marginTop: 50,
                position: "relative"
              }}>
              <Radio.Group
                style={{position: "absolute", top: 23, right: 23}}
                id={"grid-radio"}
                options={radioOptions}
                onChange={e => setGridColCount(e.target.value)}
                defaultValue={6}
                optionType="button"
                buttonStyle="solid"
              />
              <Col span={12} offset={6}>
                <Input
                  size="large"
                  placeholder={` Search Question - #${dataSource.length}`}
                  prefix={<SearchOutlined />}
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                />
              </Col>
              <Row
                gutter={[16, 24]}
                style={{
                  marginTop: 20
                }}>
                <YsEditModal
                  data={currentQuestion}
                  visible={visibleModal}
                  handleSave={editQuestion}
                  handleCancel={() => setVisibleModal(false)}
                  closeModal={() => setVisibleModal(false)}
                />
                {dataSource &&
                  Array.isArray(dataSource) &&
                  filterQuestions().map(data => (
                    <Col className="gutter-row" xs={24} md={gridColCount} span={gridColCount}>
                      <Card key={data?.questionId}>
                        {userId === data?.userId && (
                          <Row
                            style={{
                              position: "absolute",
                              top: 10,
                              right: 10
                            }}>
                            <YsEditButton
                              onClick={() => {
                                setCurrentQuestion(data);
                                setVisibleModal(true);
                              }}
                            />
                            <YsDeleteButton
                              onClick={() => {
                                deleteQuestion(data?._id);
                              }}
                            />
                          </Row>
                        )}
                        <p
                          style={{
                            fontWeight: "bold",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            marginTop: 7
                          }}>
                          Q : {data?.question}
                        </p>
                        <p
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}>
                          A : {data?.answer}
                        </p>
                        <div className={"time-ago"}>{moment(data?.updatedAt).fromNow() || ""}</div>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Card>
          </Col>
        </Content>
        <Footer>
          <Card
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              height: 140
            }}>
            <Form layout="vertical" form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
              <Row>
                <Col span={9} offset={1}>
                  <Form.Item
                    label="Question"
                    name="question"
                    rules={[{required: true, message: "Please enter the question!"}]}>
                    <Input.TextArea placeholder="Question" rows={2} />
                  </Form.Item>
                </Col>
                <Col span={9} offset={1}>
                  <Form.Item
                    label="Answer"
                    name="answer"
                    rules={[{required: true, message: "Please enter the answer!"}]}>
                    <Input.TextArea placeholder="Answer" rows={2} />
                  </Form.Item>
                </Col>
                <Col
                  span={2}
                  offset={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 7
                  }}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      <p id={"add-question-button-text"}>Add Question</p>
                      <p id={"add-question-button-plus"}>+</p>
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default App;
