import React from "react";
import {Modal, Button, Input, Form, Row} from "antd";

const YsEditModal = ({data, visible, handleSave, handleCancel, onFinishFailed, closeModal = () => {}}) => {
  const {question, answer, userId, _id} = data;
  const [form] = Form.useForm();
  form.setFieldsValue({question, answer});
  const onFinish = values => {
    const {question, answer} = values;
    if (question && answer) {
      handleSave({...values, userId, _id});
      form.setFieldsValue({});
    }
  };

  return (
    <Modal visible={visible} title="Edit Question" onCancel={handleCancel} footer={false}>
      <Form
        layout="vertical"
        form={form}
        initialValues={{question, answer}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Form.Item label="Question" name="question" rules={[{required: true, message: "Please enter the question!"}]}>
          <Input.TextArea placeholder="Question" rows={7} defaultValue={question} />
        </Form.Item>

        <Form.Item label="Answer" name="answer" rules={[{required: true, message: "Please enter the answer!"}]}>
          <Input.TextArea placeholder="Answer" rows={7} defaultValue={answer} />
        </Form.Item>

        <Row style={{display: "flex", justifyContent: "flex-end"}}>
          <Form.Item>
            <Button htmlType="back" onClick={() => closeModal()}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item style={{marginLeft: 5}}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default YsEditModal;
