import React, {useState} from "react";
import {DeleteOutlined} from "@ant-design/icons";
import {Tooltip, Popconfirm} from "antd";
import "./styles.css";

const YsDeleteButton = ({onClick = () => {}}) => {
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false);
  const tooltipVisibleProp = deletePopConfirmVisible ? {visible: false} : {};
  return (
    <Popconfirm
      title="Sure to delete?"
      onConfirm={onClick}
      visible={deletePopConfirmVisible}
      onCancel={() => setDeletePopConfirmVisible(false)}>
      <Tooltip title={"Delete Question"} {...tooltipVisibleProp}>
        <div className={"delete-button"} onClick={() => setDeletePopConfirmVisible(!deletePopConfirmVisible)}>
          <DeleteOutlined />
        </div>
      </Tooltip>
    </Popconfirm>
  );
};

export default YsDeleteButton;
